import React from "react"
import Layout from "../../components/modules/Layout"
import BodyBlocks from "../../components/technical/BodyBlocks"

const kickerPlaceholder = "Ein Platzhalter-Kicker"
const headlinePlaceholder = "Eine Platzhalter-Überschrift"
const subheadlinePlaceholder = "Eine Platzhalter-Unterzeile"
const textPlaceholder =
  "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine. Ich bin so glücklich, mein Bester, so ganz in dem Gefühle von ruhigem Dasein versunken, daß meine Kunst darunter leidet. Ich könnte jetzt nicht zeichnen, nicht einen Strich, und bin nie ein größerer Maler gewesen als in diesen Augenblicken."
const buttonsPlaceholder = [
  {
    link_text: "Primär Button",
    link: "/",
  },
  {
    link_text: "Sekundär Button",
    link: "/",
  },
]
const imagePlaceholder = {
  filename:
    "https://a.storyblok.com/f/108683/1920x1080/d6600d2022/bg_placeholder_1080p_center.jpg",
}
const captionPlaceholder = "Ich bin so glücklich, mein Bester..."
const videoPlaceholder = "https://www.youtube.com/watch?v=8o-JI8VRyKo"

const MediaPage = () => {
  const body = [
    {
      component: "hero_block",
      kicker: kickerPlaceholder,
      headline: "Hero Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      image: imagePlaceholder,
    },
    {
      component: "gallery_block",
      kicker: kickerPlaceholder,
      headline: "Gallery Block – Thumbnails: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      image_items: [
        {
          image: imagePlaceholder,
          caption: captionPlaceholder,
        },
        {
          image: imagePlaceholder,
          caption: captionPlaceholder,
        },
        {
          image: imagePlaceholder,
        },
        {
          image: imagePlaceholder,
        },
      ],
    },
    {
      component: "gallery_block",
      kicker: kickerPlaceholder,
      headline: "Gallery Block – Slider: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      view: "slider",
      image_items: [
        {
          image: imagePlaceholder,
          caption: captionPlaceholder,
        },
        {
          image: imagePlaceholder,
          caption: captionPlaceholder,
        },
        {
          image: imagePlaceholder,
        },
        {
          image: imagePlaceholder,
        },
      ],
    },
    {
      component: "image_block",
      block_width: "content",
      kicker: kickerPlaceholder,
      headline: "Image Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      aspect_ratio: "16by9",
      image: imagePlaceholder,
      caption: captionPlaceholder,
    },
    {
      component: "image_block",
      kicker: kickerPlaceholder,
      headline: "Image Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      aspect_ratio: "16by9",
      image: imagePlaceholder,
      caption: captionPlaceholder,
    },
    {
      component: "image_block",
      block_width: "screen",
      kicker: kickerPlaceholder,
      headline: "Image Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      aspect_ratio: "16by9",
      image: imagePlaceholder,
      caption: captionPlaceholder,
    },
    {
      component: "video_block",
      block_width: "content",
      kicker: kickerPlaceholder,
      headline: "Video Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      video_url: videoPlaceholder,
      caption: captionPlaceholder,
    },
    {
      component: "video_block",
      kicker: kickerPlaceholder,
      headline: "Video Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      video_url: videoPlaceholder,
      caption: captionPlaceholder,
    },
    {
      component: "video_block",
      block_width: "screen",
      kicker: kickerPlaceholder,
      headline: "Video Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      video_url: videoPlaceholder,
      caption: captionPlaceholder,
    },
    {
      component: "embed_block",
      kicker: kickerPlaceholder,
      headline: "Embed Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      embed_code:
        '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d43051.16383152667!2d8.696192722481483!3d50.11522897413446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sde!2sde!4v1660221181877!5m2!1sde!2sde" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
    },
  ]

  return (
    <Layout>
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default MediaPage
